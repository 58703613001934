(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('MfaController', MfaController);

    MfaController.$inject = ['$rootScope', '$scope', '$state', '$timeout', 'AlertService', '$translate', '$http', 'Principal', '$uibModal', 'UsersService'];

    function MfaController($rootScope, $scope, $state, $timeout, AlertService, $translate, $http, Principal, $uibModal, UsersService) {
        var vm = this;
        vm.token = '';
        vm.mfa = mfa;
        vm.resendCode = resendCode;
        vm.isResendDisabled = false;
        vm.resendCooldown = 60;
        vm.timeoutValue = 600000

        var resendCooldownTimer;

        // Timeout for 10 minutes
        var timeoutPromise = $timeout(onTimeout, vm.timeoutValue);

        $scope.$watch('vm.token', function(newVal, oldVal) {
            if (newVal !== oldVal) {
                $timeout.cancel(timeoutPromise);
                timeoutPromise = $timeout(onTimeout, vm.timeoutValue);
            }
        });

        function onTimeout() {
            AlertService.error($translate.instant('mfa.timeout'));
            $state.go('login');
        }

        function mfa(event) {
            event.preventDefault();
            $timeout.cancel(timeoutPromise);

            $http.post($rootScope.API_URL + 'api/verify-2FA', { code: vm.token })
                .then(function(response) {
                    if (response.status === 200) {
                        Principal.identity(true).then(function() {
                                $rootScope.$broadcast('authenticationSuccess');
                                AlertService.success($translate.instant('mfa.success'));
                                $state.go('documents');
                            });
                    }
                    else {
                        AlertService.error($translate.instant('mfa.invalid'));
                    }
                }).catch(function(error) {
                if (error.status === 429) {
                    AlertService.error($translate.instant('login.attempts', { mfaTimeCooldown: error.data.time }));
                }
                else {
                    AlertService.error($translate.instant('mfa.invalid'));
                }
                });
        }

        function resendCode() {
            if (vm.isResendDisabled) return;

            $http.post($rootScope.API_URL + 'api/resend-2FA-code')
                .then(function(response) {
                    if (response.status === 200) {
                        AlertService.success($translate.instant('mfa.resendSuccess'));
                        startResendCooldown();
                    } else {
                        AlertService.error($translate.instant('mfa.resendError'));
                    }
                }).catch(function() {
                    AlertService.error($translate.instant('mfa.resendError'));
                });
        }

        function startResendCooldown() {
            vm.isResendDisabled = true;
            vm.resendCooldown = 60;

            resendCooldownTimer = $timeout(function countdown() {
                vm.resendCooldown--;
                if (vm.resendCooldown > 0) {
                    resendCooldownTimer = $timeout(countdown, 1000);
                } else {
                    vm.isResendDisabled = false;
                }
            }, 1000);
        }

        $scope.$on('$destroy', function() {
            $timeout.cancel(timeoutPromise);
            $timeout.cancel(resendCooldownTimer);
        });
    }
})();
