(function() {
    'use strict';

    /*Using this directive requires the table to be wrapped in a DIV element*/
    angular
        .module('edistradadhluiApp')
        .directive("fixTable", ['$compile', function($compile) {
            return {
                restrict: 'A',
                scope: false,
                link: function(scope, element, attrs) {
                    var table = $(element),
                        fixedHeader,
                        fixedFooter,
                        wrapper = table.closest('div');

                    fixedHeader = table.clone();
                    fixedHeader.find("tbody").remove().end() //Remove body
                        .find("tfoot").remove().end() //Remove footer
                        .addClass("alt-fixed") //Add class that fixes this element
                        .removeAttr('fix-table') //Remove this directive
                        .insertBefore(table); //Insert before table
                    $compile(fixedHeader)(scope); //Inject into AngularJS

                    fixedFooter = table.clone();
                    fixedFooter.find("tbody").remove().end() //Remove body
                        .find("thead").remove().end() //Remove footer
                        .addClass("alt-fixed") //Add class that fixes this element
                        .removeAttr('fix-table') //Remove this directive
                        .insertAfter(table); //Insert after table
                    fixedFooter.css('bottom', 0); //Fix footer at the bottom, instead of top
                    $compile(fixedFooter)(scope); //Inject into AngularJS

                    function resizeFixed() {
                        var tableOffsetLeft = table.offset().left,
                            tableCutLeft = wrapper.offset().left - tableOffsetLeft,
                            tableCutRight = table.width() - wrapper.width() - tableCutLeft,
                            tableInnerWidth = table.width() - tableCutRight;

                        resizeFixedColumns(fixedHeader, table);
                        resizeFixedColumns(fixedFooter, table);

                        function resizeFixedColumns(elementToResize, modelElement) {
                            elementToResize.css('left', tableOffsetLeft); //Move element to left, just as modelElement
                            elementToResize.css('clip', 'rect(0px ' + tableInnerWidth + 'px auto ' + tableCutLeft + 'px)'); //Clip element to not stick out of the wrapper
                            //Resize columns taking colspan attribute into consideration
                            var column = 0;
                            var elementSelector = "th";
                            elementToResize.find("th").each(resizeFixedColumn);
                            elementSelector = "tfoot td";
                            elementToResize.find("td").each(resizeFixedColumn);

                            function resizeFixedColumn(index, element) {
                                if (index == 0) column = 0;
                                var matchingColumn = modelElement.find(elementSelector).eq(column++);
                                if (!$(matchingColumn).hasClass('ng-hide')) {
                                    $(this).css("min-width", matchingColumn.outerWidth(true) + "px");
                                    $(this).css("max-width", matchingColumn.outerWidth(true) + "px");
                                } else {
                                    $(this).css("min-width", "0");
                                    $(this).css("max-width", "0");
                                }
                            }
                        }
                    }

                    function scrollFixed() {
                        try { //To avoid console errors before AngularJS initialization
                            var fixedPart = $('#fixed')[0] != undefined ? $('#fixed') : $('#shell'),
                                windowPart = $(window),
                                topPosition = fixedPart.offset().top - windowPart.scrollTop() + fixedPart.outerHeight(),
                                offsetTop = fixedPart.offset().top + fixedPart.outerHeight(),
                                offsetBottom = windowPart.scrollTop() + windowPart.outerHeight(),
                                tableOffsetTop = table.offset().top,
                                tableOffsetBottom = tableOffsetTop + table.outerHeight();

                            if ((offsetTop < tableOffsetTop || offsetTop > tableOffsetBottom) && !fixedHeader.is(":hidden")) {
                                fixedHeader.css('top', topPosition + "px");
                                fixedHeader.hide();
                            } else if (offsetTop >= tableOffsetTop && offsetTop <= tableOffsetBottom) {
                                var headerSpaceLeft = tableOffsetBottom - fixedHeader.outerHeight() - offsetTop;
                                fixedHeader.css('top', (headerSpaceLeft > 0 ? topPosition : topPosition + headerSpaceLeft) + "px");
                                fixedHeader.show();
                            }

                            if (offsetBottom < tableOffsetTop + fixedFooter.outerHeight() + fixedHeader.outerHeight() || offsetBottom > tableOffsetBottom) {
                                fixedFooter.hide();
                            } else if (offsetBottom >= tableOffsetTop && offsetBottom <= tableOffsetBottom && fixedFooter.is(":hidden")) {
                                fixedFooter.show();
                            }
                        } catch (e) {
                            if (!e instanceof TypeError)
                                throw e;
                        } finally {}
                    }

                    function adjustFixed() {
                        resizeFixed();
                        scrollFixed();
                    }

                    //This function shows changes made to footer and header
                    function move() {
                        $(window).scrollTop($(window).scrollTop() + 1);
                        $(window).scrollTop($(window).scrollTop() - 1);
                    }

                    //Timeout prevents huge lag due to massive amount of function calls
                    var handlerTimeout;
                    //React to window resize, window scroll, wrapper DIV scroll and table header DOM manipulation events
                    $(window).resize(ResizeAndScrollHandler);
                    $(window).scroll(ResizeAndScrollHandler);
                    $(table.closest('div')).scroll(ResizeAndScrollHandler);
                    const theadObserver = new MutationObserver(DOMModificationHandler);

                    theadObserver.observe(table.find('thead')[0], {
                        childList: true,
                        subtree: true,
                        characterData: true 
                    });

                    const tableObserver = new MutationObserver(DOMModificationHandler);

                    tableObserver.observe(table[0], {
                        childList: true,
                        subtree: true       
                    });

                    function ResizeAndScrollHandler() {
                        clearTimeout(handlerTimeout);
                        adjustFixed();
                    }

                    function DOMModificationHandler() {
                        clearTimeout(handlerTimeout);
                        handlerTimeout = setTimeout(function() {
                            adjustFixed();
                            move();
                        }, 100);
                    }

                    table.on('$destroy', function() {
                        $(window).unbind('resize', ResizeAndScrollHandler);
                        $(window).unbind('scroll', ResizeAndScrollHandler);
                    });
                }
            };
        }]);
})();
