(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('ClientController', ClientController);

    ClientController.$inject = ['$scope', '$state', '$stateParams', 'UsersService', 'ClientsService', 'UtilService', 'MailTemplatesService', 'filterFilter', 'Principal', 'AlertService', '$translate'];

    function ClientController($scope, $state, $stateParams, UsersService, ClientsService, UtilService, MailTemplatesService, filterFilter, Principal, AlertService, $translate) {
        var vm = this;

        var defaultFileTypes = {
            CSV: false,
            XML: false,
            PDF: false
        };

        /*Declare variables*/
        vm.filter = {
            data: {
                clientNumber: ""
            },
            clear: function() {
                vm.filter.data.clientNumber = "";
            },
            save: function() {
                $state.transitionTo($state.current, vm.filter.data, {
                    location: true,
                    inherit: true,
                    notify: false
                });
            },
            read: function() {
                angular.copy($stateParams, vm.filter.data);
            }
        };

        vm.ActivityTypes = [{
            name: 'ACCOUNT_OPENED',
            text: function() {
                return $translate.instant("clients.client.accountOpen");
            }
        }, {
            name: 'EMAIL_ADDED',
            text: function() {
                return $translate.instant("clients.client.emailAdded");
            }
        }, {
            name: 'REGISTRATION_FAIL',
            text: function() {
                return $translate.instant("clients.client.registrationFail");
            }
        }, {
            name: 'PASSWORD_CHANGE',
            text: function() {
                return $translate.instant("clients.client.passwordChange");
            }
        }, {
            name: 'INVOICE_WITH_LINK',
            text: function() {
                return $translate.instant("clients.client.invoiceWithLink");
            }
        }, {
            name: 'INVOICE_WITH_PDF',
            text: function() {
                return $translate.instant("clients.client.invoiceWithPdf");
            }
        }, {
            name: 'INVOICE_WITH_CSV',
            text: function() {
                return $translate.instant('clients.client.invoiceWithCsv');
            }
        }, {
            name: 'INVOICE_WITH_LINK_AND_XLSX',
            text: function() {
                return $translate.instant("clients.client.invoiceWithLinkXlsx");
            }
        }, {
            name: 'INVOICE_WITH_PDF_AND_XLSX',
            text: function() {
                return $translate.instant("clients.client.invoiceWithPdfXlsx");
            }
        }, {
            name: 'INVOICE_WITH_PDF_AND_CSV',
            text: function() {
                return $translate.instant("clients.client.invoiceWithPdfCsv");
            }
        },
            // {
        //     name: 'CORRECTION_INVOICE',
        //     text: function() {
        //         return $translate.instant("clients.client.correctionsInvoice")
        //     }
        // },
        {
            name: 'INVOICE_TOO_LARGE',
            text: function() {
                return $translate.instant("clients.client.invoiceTooLarge");
            }
        }];

        vm.clientTemplates = {
            templatesNL: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titlesNL: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            templatesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titlesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            templatesFR: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titlesFR: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            templatesDE: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titlesDE: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_CSV: {
                    name: null,
                    template_id: null
                },
                // CORRECTION_INVOICE: {
                //     name: null,
                //     template_id: null
                // },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            }
        };

        vm.dhlEmailTempaltes = null;
        vm.dhlClientEmailTempaltesIds = null;
        vm.templatesToSend = [];
        vm.onHoldCheckboxShow = false;
        vm.onHoldCheckboxEditable = false;

        vm.data = {
            client: null,
            user: null,
            users: []
        };

        /*Declare functions*/
        vm.getAllEmailTemplates = getAllEmailTemplates;
        vm.getUserTemplatesId = getUserTemplatesId;
        vm.sendNewUserTemplates = sendNewUserTemplates;
        vm.getCommonElementFromLists = getCommonElementFromLists;


        //Controller init
        vm.init = function() {
            vm.filter.read();
            if (UtilService.isFilled(vm.filter.data)) {
                if (Principal.hasClientRead()) {
                    vm.onHoldCheckboxShow = true;
                    vm.onHoldCheckboxEditable = Principal.hasClientWrite();
                }
                vm.client.get();
            } else
                $state.go('clients');
        };

        function fixURL(url) {
            if( url != null && !(url.indexOf('BENELUXUI') !== -1) && (url.indexOf('dev') !== -1) ) {
                return url.replace('edistrada2', 'BENELUXUI/edistrada2');
            }else {
                return url;
            }
        }

        //Managing client
        vm.client = {
            get: function() {
                ClientsService.findByClientNumber(vm.filter.data.clientNumber)
                    //If successful
                    .then(vm.client.then)
                    //If there were errors
                    .catch(function(response) {
                        $state.go('clients');
                    })
                    .finally(function(response) {});
            },
            then: function(response) {

                //add 'BENELUXUI' to url if missing
                if(response.data._links['self'])
                    response.data._links['self'].href = fixURL(response.data._links['self'].href);
                if(response.data._links['nlClient'])
                    response.data._links['nlClient'].href = fixURL(response.data._links['nlClient'].href);
                if(response.data._links['beneluxTemplates'])
                    response.data._links['beneluxTemplates'].href = fixURL(response.data._links['beneluxTemplates'].href);
                if(response.data._links['ftpConfiguration'])
                    response.data._links['ftpConfiguration'].href = fixURL(response.data._links['ftpConfiguration'].href);
                if(response.data._links['beneluxUsers'])
                    response.data._links['beneluxUsers'].href = fixURL(response.data._links['beneluxUsers'].href);

                //Set data to controller data
                vm.data.client = response.data;

                if(vm.data.client.ftpConfiguration) {
                    //Remove password hash from ftpConnection
                    vm.data.client.ftpConfiguration.password = null;

                    //Basic values of ftpConnection
                    vm.data.client.ftpConfiguration.fileTypesArray = Object.assign({}, defaultFileTypes);

                    //Split ftpConnection file types into array
                    if(vm.data.client.ftpConfiguration.fileTypes) {
                        var fileTypes = vm.data.client.ftpConfiguration.fileTypes.split(",");

                        for(var i = 0; i < fileTypes.length; i++) {
                            if(fileTypes[i] in defaultFileTypes)
                                vm.data.client.ftpConfiguration.fileTypesArray[fileTypes[i]] = true;
                        }
                    }

                    vm.data.client.isFtpActive = true;
                } else {
                    vm.data.client.ftpConfiguration = {
                        fileTypesArray: Object.assign({}, defaultFileTypes)
                    };
                }

                //Get his users
                vm.client.getUsers();

                //Write state to URL
                vm.filter.save();

                //Get his templates
                vm.getAllEmailTemplates();
            },
            getUsers: function() {
                UtilService.get /*his users*/ (vm.data.client, 'beneluxUsers', null, null)
                    .then(function(response) {
                        //Set users to client
                        if(response.data._embedded != undefined)
                            vm.data.client.users = response.data._embedded[Principal.getCurrentEntityLowerCase()+'Users'];
                        else
                            vm.data.client.users = [];
                    })
                    .catch(function(response) {
                        vm.data.client.users = [];
                    });
            },
            cancel: function() {
                vm.client.get();
            },
            update: function() {
                clientServiceExecutor(
                    ClientsService.update, 
                    vm.data.client, 
                    $translate.instant("clients.client.changesSaved"), 
                    $translate.instant("clients.client.saveFailed"),
                    vm.data.client.isFtpActive
                );
            },
            updateFtpConfig: function() {
                if(vm.data.client.ftpConfiguration.fileTypesArray) {
                    vm.data.client.ftpConfiguration.fileTypes = arrayToString(vm.data.client.ftpConfiguration.fileTypesArray);
                }

                clientServiceExecutor(
                    ClientsService.updateFtpConfig, 
                    vm.data.client, 
                    $translate.instant("clients.client.ftpConfigurationUpdateSuccess"), 
                    $translate.instant("clients.client.ftpConfigurationUpdateFailure"),
                    true
                );
            },
            deleteFtpConfig: function() {
                clientServiceExecutor(
                    ClientsService.deleteFtpConfig, 
                    vm.data.client, 
                    $translate.instant("clients.client.ftpConfigurationDeleteSuccess"), 
                    $translate.instant("clients.client.ftpConfigurationDeleteFailure"),
                    false
                );
            }
        };

        function clientServiceExecutor(indexFunction, data, successAlert, errorAlert, isFtpActive) {
            indexFunction(data)
                .then(function(response) {
                    AlertService.success(successAlert);
                    vm.data.client.isFtpActive = isFtpActive
                }).catch(function(response) {
                    AlertService.error(errorAlert);
                })
        }

        function arrayToString(array) {
            var keys = [];

            for (var key in array) {
                if (array.hasOwnProperty(key) && array[key]) {
                    keys.push(key);
                }
            }

            return keys.join(",");
        }

        vm.user = {
            getByEmail: function(email) {
                if(Principal.hasUserRead()){
                    return UsersService.getEmailsLike({ email: email.toLowerCase() })
                        .then(function(response) {
                            if (!response.data._embedded) {
                                return "";
                            }
                            return response.data._embedded.ediUsers.map(function(user) {
                                return user.email;
                            });
                        });
                }
            },
            add: function() {
                if (typeof vm.data.client.users !== 'undefined') {
                    if (vm.data.client.users.map(function(user) {
                        return user.email;
                    }).indexOf(vm.data.user) == -1) {
                        if (angular.isDefined(vm.data.user) && vm.data.user != "" && vm.data.user != null) {
                            if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(vm.data.user)) {
                                vm.data.client.users.push({ email: vm.data.user });
                                vm.data.user = "";
                            } else {
                                AlertService.error($translate.instant("clients.client.invalidEmail"));
                            }
                        }
                    } else {
                        AlertService.error($translate.instant("clients.client.duplicateEmail"));
                    }
                } else {
                    if (angular.isDefined(vm.data.user) && vm.data.user != "" && vm.data.user != null) {
                        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(vm.data.user)) {
                            vm.data.client.users = [{ email: vm.data.user }];
                            vm.data.user = "";
                        } else {
                            AlertService.error($translate.instant("clients.client.invalidEmail"));
                        }
                    }
                }

            },
            delete: function() {
                var index = vm.data.client.users.map(function(user) {
                    return user.email;
                }).indexOf(vm.data.user);

                if (index != -1) {
                    vm.data.client.users.splice(index, 1);
                    vm.data.user = "";
                }
            }
        };

        //Define a watch for user list select
        $scope.$watch('vm.selection', function(newValue) {
            if (!(newValue == undefined) && newValue.length != 0) {
                vm.data.user = newValue[0].email;
            }
        });

        $scope.$watch('vm.data.client.nip', function() {
            if (vm.data.client && UtilService.isFilled(vm.data.client.nip))
                vm.data.client.nip = vm.data.client.nip.replace(/\W/g, '');
        });

        //Navigating
        vm.nav = {
            clients: function() {
                $state.go('clients');
            },
            user: function(user) {
                $state.go('user', user);
            }
        };

        function getAllEmailTemplates() {
            if (Principal.hasTemplateAccess()) {
                MailTemplatesService.getAllTemplates().then(function(response) {
                    if (response.data._embedded != null) {
                        vm.dhlEmailTempaltes = response.data._embedded[Principal.getCurrentEntityLowerCase()+'Templates'];
                        vm.getUserTemplatesId();
                    } else {
                        vm.clientErrorText = $translate.instant('clients.client.templatesDownloadFailed');
                        vm.clientSuccessText = null;
                    }
                }).catch(function(response) {
                    vm.clientErrorText = $translate.instant("clients.client.templatesDownloadFailed");
                    vm.clientSuccessText = null;
                });
            }
        }

        function getUserTemplatesId() {
            if (Principal.hasTemplateAccess()) {
                ClientsService.getClientTemplates(vm.data.client.ediClientId).then(function(response) {
                    vm.dhlClientEmailTempaltesIds = response.data;

                    var id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesNL.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesNL.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesNL.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'nl' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesNL.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'en' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];


                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesFR.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesFR.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesFR.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'fr' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesFR.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.titlesDE.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.titlesDE.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_CSV', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_CSV = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    // id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    // vm.clientTemplates.templatesDE.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'de' }, true), vm.dhlClientEmailTempaltesIds);
                    vm.clientTemplates.templatesDE.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                }).catch(function(response) {
                    vm.clientErrorText = $translate.instant("clients.client.templatesDownloadFailed");
                    vm.clientSuccessText = null;
                });
            }
        }

        function getCommonElementFromLists(list1, list2) {
            var id;
            angular.forEach(list1, function(value, key) {
                if (list2.indexOf(value.ediTemplateId) > -1) {
                    id = value.ediTemplateId;
                }
            });
            return id;
        }

        //client + listofid
        function sendNewUserTemplates() {
            if (Principal.hasTemplateAccess()) {
                vm.templatesToSend = [];

                vm.templatesToSend.push(vm.clientTemplates.titlesNL.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.titlesNL.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesNL.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.templatesNL.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.templatesNL.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesNL.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.titlesEN.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.titlesEN.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.templatesEN.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.templatesEN.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.titlesFR.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.titlesFR.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesFR.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.templatesFR.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.templatesFR.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesFR.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.titlesDE.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.titlesDE.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesDE.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.templatesDE.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.INVOICE_WITH_PDF_AND_CSV.ediTemplateId);
                // vm.templatesToSend.push(vm.clientTemplates.templatesDE.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesDE.INVOICE_TOO_LARGE.ediTemplateId);

                ClientsService.postClientTemplates(vm.data.client.ediClientId, vm.templatesToSend).then(function(response) {
                    vm.clientErrorText = null;
                    vm.clientSuccessText = $translate.instant("clients.client.templatesEditSuccess");
                }).catch(function(response) {
                    vm.clientErrorText = $translate.instant("clients.client.templatesEditFail");
                    vm.clientSuccessText = null;
                });
            }
        }

        //Init controller
        vm.init();
    }
})();
