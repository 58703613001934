(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('HelpController', HelpController);

    HelpController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$translate'];

    function HelpController($scope, $rootScope, $state, $stateParams, $translate) {
        var vm = this;

        //Declare variables
        vm.filter = null;
        vm.data = null;
        vm.language = function() {
            return $translate.use();
        };

        //Declare functions
        vm.init = init;
        vm.goToId = goToId;
        vm.goToTop = goToTop;
        vm.getUrl = getUrl;

        //Init functions
        function init() {}

        function goToId(event) {
            event.preventDefault();
            // 150 below is fixed header's size
            $('html, body').animate({
                scrollTop: Math.min($(document).height() - $('body').offset().top - window.innerHeight, $(event.target.attributes.href ?
                    event.target.attributes.href.value : event.target.parentElement.attributes.href.value).offset().top - 150)
            }, 1000, 'swing');
        }

        function goToTop() {
            $('html, body').animate({
                scrollTop: Math.min($(document).height() - $('body').offset().top - window.innerHeight, $('#topid').offset().top - 150)
            }, 1000, 'swing');
        }

        function getUrl(translation) {
            return $translate.instant("help." + translation + "." + $rootScope.API_DOMAIN);
        }

        //Init controller
        vm.init();
    }
})();
