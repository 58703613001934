(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PdfViewController', PdfViewController);

    PdfViewController.$inject = ['$scope', '$state', '$stateParams', 'DocumentsService', 'UsersService', 'Principal', 'AlertService', "$sce", "PdfViewService", 'Blob', '$compile', 'tmhDynamicLocale', '$translate', '$http'];

    function PdfViewController($scope, $state, $stateParams, DocumentsService, UsersService, Principal, AlertService, $sce, PdfViewService, Blob, $compile, tmhDynamicLocale, $translate, $http) {
        var vm = this;
        vm.translate = $translate.instant;
        vm.userOnePdfPage = null;
        vm.documentNumber = null;
        vm.init = init;
        vm.getDownloadFullPdfURL = getDownloadFullPdfURL;
        vm.getDownloadXlsxURL = getDownloadXlsxURL;
        vm.getDownloadCsvURL = getDownloadCsvURL;
        vm.getDownloadXmlURL = getDownloadXmlURL;
        vm.goToDisputeForm = goToDisputeForm;
        vm.downloadError = null;
        vm.pdfUrl = DocumentsService.getPdfURL($stateParams.uuid, false, false);
        vm.pdfViewer = './external/pdf-view/web/viewer.html?file=' + vm.pdfUrl + '#locale=' + tmhDynamicLocale.get();
        vm.isExcelAvailable = false;
        vm.isCSVAvailable = false;
        vm.isXMLAvailable = false;

        $('[data-toggle="popover"]').popover({
            container: 'body',
            title: vm.translate('pdfView.submitDispute.title'),
            html: true,
            sanitize: false,
            content: function () {
                return '<div id="popover-content"><button class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button><iframe iframe-set-dimensions-onload name="disputeForm" src="./app/views/pdfView/disputeForm/disputeForm.html" style="border:none; height:100%; width:100%" onload="adjustPopover(&quot;popover&quot;, this);"></iframe></div>';
            }
        });
        // Remove any existing click event handlers on the element with data-toggle="popover"
        $('[data-toggle="popover"]').off('click');
		// Attach a click event handler to manually trigger the popover
		$('[data-toggle="popover"]').on('click', function (e) {
			e.stopPropagation(); // Prevent double click issue
			$(this).popover('toggle');
		});
        $(document).on("click", ".popover .close" , function(){
            $(this).parents(".popover").popover('hide');
        });

        function init() {
            if ($stateParams.uuid == null) {
                $state.go('404');
            }


            if (Principal.isAuthenticated()) {
                PdfViewService.getInvoiceStylesheet($stateParams.uuid).then(function(response) {
                    vm.userOnePdfPage = response.data.stylesheet;
                    vm.documentNumber = response.data.number;
                }).catch(function(response) {
                    $state.go('404');
                });
            }

            //Check whether Excel file is ready for download
            DocumentsService.isAvailable($stateParams.uuid, "XLSX")
            .then(function(response){
                vm.isExcelAvailable = response.data;
            });

            //TODO implement backend
            DocumentsService.isAvailable($stateParams.uuid, "CSV")
            .then(function(response){
                vm.isCSVAvailable = response.data;
            });

            //TODO TJ
            DocumentsService.isAvailable($stateParams.uuid, "XML")
            .then(function(response){
                vm.isXMLAvailable = response.data;
            });
        }

        function getDownloadFullPdfURL(forceFullPDF) {
            return DocumentsService.getPdfURL($stateParams.uuid, true, forceFullPDF);
        }

        function getDownloadXlsxURL() {
            return DocumentsService.getInvoiceXlsxURL($stateParams.uuid);
        }

        function getDownloadCsvURL() {
            return DocumentsService.getInvoiceCsvURL($stateParams.uuid);
        }

        function getDownloadXmlURL() {
            return DocumentsService.getInvoiceXmlURL($stateParams.uuid);
        }

        function goToDisputeForm() {
            var url = $state.href('disputeForm', { uuid: $stateParams.uuid });
            window.open(url, 'disputeForm');
        }

        //Init controller
        vm.init();

        /*
            The piece of code below shows AngularJS Loading Spinner until PDF document is not loaded. It's a workaround, beacause IFrame does not allow to capture its internal events outside.
            In order to show the spinner, we have to have at least one pending HTTP request - that's why empty object is inserted into the $http.pendingRequests array.
            When PDF document is loaded - the faked request is removed.
        */
        var loadingRequestPlaceholder = { notice: "Do not remove this object. It resides here to show AngularJS Loading Spinner. It will be removed automatically. Author: DD" };
        $http.pendingRequests.push(loadingRequestPlaceholder);

        var PDFWrapper = document.getElementById('PDFWrapper');
        PDFWrapper.onload = function() {
            var viewerElement = this.contentDocument.getElementById('viewer');
        
            if (viewerElement) {
                var mutationCallback = function(mutationsList, observer) {
                    removeRequest();
        
                    observer.disconnect();
                };
        
                var config = {
                    childList: true,
                    subtree: true,
                    characterData: true
                };
        
                var observer = new MutationObserver(mutationCallback);
        
                observer.observe(viewerElement, config);
            }
        };  

        $scope.$on("$destroy", function() {
            removeRequest();
        });

        function removeRequest(event) {
            var index = $http.pendingRequests.indexOf(loadingRequestPlaceholder);
            if (index !== -1) $http.pendingRequests.splice(index, 1);
            if (event)
                $scope.$apply();
        }
    }
})();
